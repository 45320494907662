import type {
  Alert,
  Antibiotics,
  ComplexInteraction,
  Measurement,
  Metadata,
  Mic,
  Notes
} from "@/domain/models/ast";

export interface AstReport {
  metadata: Metadata;
  isApproved: boolean;
  alerts: Array<Alert>;
  antibiotics: Antibiotics;
  notes: Notes;
  measurement: Measurement;
  mics: Array<Mic>;
  complexInteractions: Array<ComplexInteraction>;
}

export const emptyAstReport: AstReport = {
  alerts: [],
  antibiotics: {
    filteredAntibiotics: {},
    onlyAllowedAntibiotics: []
  },
  complexInteractions: [],
  isApproved: false,
  measurement: {
    pelletNamesImage: ""
  },
  metadata: {
    astId: "",
    bacteriaType: "",
    owner: "",
    population: "",
    sampleType: "",
    createdAt: 0
  },
  mics: [],
  notes: {
    notes: [],
    technicianInput: ""
  }
};
