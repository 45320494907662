<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import type { Antibiotic, Antibiotics, FilteredAntibiotics } from "@/domain/models/ast";
import utils from "@/domain/utils/antibioticsUtils";
import AstAntibioticList from "@/components/AstAntibioticList.vue";
import AppButton from "@/components/AppButton.vue";
import AppDivider from "@/components/AppDivider.vue";

const props = defineProps<{ antibiotics: Antibiotics }>();
const { t } = useI18n();
const atbs = props.antibiotics;
const hasSelectiveAtbs = computed<boolean>(() => utils.hasSelectiveAtbs(atbs));
const testedAtbs = computed<Array<Antibiotic>>(() => utils.getTestedAtbs(atbs));
const inferredAtbs = computed<Array<Antibiotic>>(() => utils.getInferredAtbs(atbs));
const filteredAtbs = computed<FilteredAntibiotics>(() => utils.getFilteredAtbs(atbs));
const selectiveToggle = ref<string>(hasSelectiveAtbs.value ? "selective" : "all");
</script>
<template>
  <div>
    <app-divider />

    <p class="font-weight-medium mb-4">{{ t("antibiotics.title") }}</p>

    <v-btn-toggle
      density="compact"
      mandatory
      v-model="selectiveToggle"
      v-if="hasSelectiveAtbs"
      class="mb-4"
    >
      <app-button class="all-antibiotics mr-2" :text="t('antibiotics.all')" value="all" />
      <app-button
        class="selective-antibiotics"
        :text="t('antibiotics.selective')"
        value="selective"
      />
    </v-btn-toggle>

    <div v-if="selectiveToggle === 'selective'" class="mb-4">
      <p class="font-very-small color-secondary mb-4">{{ t("antibiotics.selective_text") }}</p>
      <div v-for="(antibiotics, type) in filteredAtbs" :key="type">
        <ast-antibiotic-list
          :header="`${t('antibiotics.administration')} : ${utils.formatAdminType(type as string)}`"
          :antibiotics="antibiotics"
        />
      </div>
    </div>

    <div v-else>
      <ast-antibiotic-list :header="t('antibiotics.tested')" :antibiotics="testedAtbs" />
      <ast-antibiotic-list :header="t('antibiotics.inferred')" :antibiotics="inferredAtbs" />
    </div>
  </div>
</template>
