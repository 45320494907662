import type { Antibiotic, Antibiotics, FilteredAntibiotics } from "@/domain/models/ast";
import { SIRI_MAPPING } from "@/domain/constants/siriMapping";

const REGEX_MIC_OR_G = /\s+(?:\(*[mM][iI][cC]\)*|[\d.-]+\s*µ[gG])/;
const REGEX_G = /\s+[\d.-]+\s*µ[gG]/;

class AntibioticsUtils {
  getSiriMapping(antibiotic: Antibiotic): string {
    const esVal = antibiotic.siri || antibiotic.sir;
    return SIRI_MAPPING[esVal];
  }

  getTestedAtbs(antibiotics: Antibiotics) {
    const testedAntibiotics = antibiotics.onlyAllowedAntibiotics.filter(
      (atb: Antibiotic) => !this.isInferredAtb(atb)
    );
    const parsedAtbs = JSON.parse(JSON.stringify(testedAntibiotics));
    const testedAntibioticWithMicRequest: Array<Antibiotic> = parsedAtbs
      .filter((antibiotic: Antibiotic) => antibiotic.siri === "request M.I.C.")
      .map((antibiotic: Antibiotic) => {
        antibiotic.name = antibioticsUtils.removeDosageAndMic(antibiotic.name);
        antibiotic.siri = antibiotic.sir;
        return { ...antibiotic };
      });
    testedAntibiotics.push(...testedAntibioticWithMicRequest);
    const formattedAtbs = this.formatAtbNames(testedAntibiotics);
    return this.sortAtbBySiriLabel(formattedAtbs)
  }

  getInferredAtbs(antibiotics: Antibiotics) {
    const atbs = antibiotics.onlyAllowedAntibiotics.filter((atb: Antibiotic) =>
      this.isInferredAtb(atb)
    );
    const formattedAtbs = this.formatAtbNames(atbs);
    return this.sortAtbBySiriLabel(formattedAtbs);
  }

  getFilteredAtbs(antibiotics: Antibiotics): FilteredAntibiotics {
    const filteredAntibiotics = antibiotics.filteredAntibiotics;
    const finalFilteredAntibiotics: FilteredAntibiotics = {}
    Object.entries(filteredAntibiotics).forEach(([key, antibiotics]) => {
      if (antibiotics.length) {
        finalFilteredAntibiotics[key] = this.formatAtbNames(this.sortAtbBySiriLabel(antibiotics));
      }
    });
    return finalFilteredAntibiotics;
  }

  isS(antibiotic: Antibiotic): boolean {
    return this.getSiriMapping(antibiotic) === "S";
  }

  hasSelectiveAtbs(antibiotics: Antibiotics) {
    const filteredAntibiotics = antibiotics.filteredAntibiotics;
    for (const key in filteredAntibiotics) {
      if (filteredAntibiotics[key].length) {
        return true;
      }
    }
    return false;
  }

  formatAdminType(type: string): string {
    switch (type) {
      case "ORAL":
        return "Oral";
      case "ORAL_FEMALE":
        return "Oral (Adults - Female)";
      case "ORAL_MALE":
        return "Oral (Adults - Male)";
      default:
        return type;
    }
  }

  private capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  private removeDosageAndMic(antibiotic: string): string {
    return antibiotic.replace(REGEX_MIC_OR_G, "");
  }

  private isInferredAtb(atb: Antibiotic): boolean {
    return atb.sir === "" || (atb.inhibitionZoneDiameter === -1.0 && atb.cmi === -1);
  }

  private formatAtbNames(antibiotics: Array<Antibiotic>) {
    return antibiotics.map((antibiotic: Antibiotic) => {
      // Special case because of i2a implementation, if "ATB_NAME (MIC)" has a breakpoint it's SIRI will be "MIC = @mic mg/l SIRI"
      if (antibiotic.cmi > 0 && antibiotic.siri === "MIC = @mic mg/l")
        antibiotic.siri = antibiotic.sir;

      const antibioticName = this.capitalizeFirstLetter(antibiotic.name);
      if (antibiotic.cmi > 0 || antibiotic.siri === "request M.I.C.") {
        const strippedAtbName = antibioticName.replace(REGEX_MIC_OR_G, "");
        antibiotic.name = `${strippedAtbName} (MIC)`;
      } else {
        const strippedAtbName = antibioticName.replace(REGEX_G, "");
        antibiotic.name = strippedAtbName.replace("(mic)", "(MIC)");
      }
      return antibiotic;
    });
  }

  private compareAtbLabel(a: Antibiotic, b: Antibiotic): number {
    const getPriority = (antibiotic: Antibiotic) => {
      const siriValue = this.getSiriMapping(antibiotic);
      switch (siriValue) {
        case "S":
          return 0;
        case "I":
          return 1;
        case "R":
          return 2;
        case "N/A":
          return 3;
        default:
          return 4;
      }
    };

    const aPrior = getPriority(a);
    const bPrior = getPriority(b);

    if (aPrior === bPrior) {
      return a.name < b.name ? -1 : 1;
    } else {
      return aPrior < bPrior ? -1 : 1;
    }
  }

  private sortAtbBySiriLabel(antibiotics: Array<Antibiotic>): Array<Antibiotic> {
    return antibiotics.sort((a: Antibiotic, b: Antibiotic) =>
      antibioticsUtils.compareAtbLabel(a, b)
    );
  }
}

const antibioticsUtils = new AntibioticsUtils();

Object.freeze(antibioticsUtils);

export default antibioticsUtils;
