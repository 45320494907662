import "@/assets/main.scss";
import App from "@/App.vue";
import { createApp } from "vue";
import router from "@/router";
import i18n from "@/i18n";
import theme from "@/theme";

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(theme);
app.mount("#app");
