<script setup lang="ts">
import { computed } from "vue";

import type { Antibiotic } from "@/domain/models/ast";
import utils from "@/domain/utils/antibioticsUtils";

const props = defineProps<{ antibiotic: Antibiotic }>();

const siriLabel = computed<string>(() => utils.getSiriMapping(props.antibiotic));
const isSiriLabelS = computed<boolean>(() => utils.isS(props.antibiotic));
</script>

<template>
  <div class="antibiotic-line d-flex justify-space-between font-small my-2">
    <p>{{ antibiotic.name }}</p>
    <p
      v-bind:class="{
        'font-weight-medium text-white bg-black text-center circular': isSiriLabelS,
        'pr-1': !isSiriLabelS
      }"
    >
      {{ siriLabel }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.circular {
  border-radius: 50%;
  width: 20px;
}
</style>
