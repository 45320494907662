<script lang="ts" setup>
import { useNavigatorLanguage } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import { watch } from "vue";

const { language } = useNavigatorLanguage();
const { locale } = useI18n();
watch(language, () => {
  if (language.value) {
    locale.value = language.value;
  }
});
</script>
<template>
  <router-view/>
</template>