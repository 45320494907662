import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
// @ts-ignore
import { createI18n } from "vue-i18n";

export default createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: en,
    fr: fr,
    es: es
  },
  legacy: false
});
