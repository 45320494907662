<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div class="my-2">
    <p>{{ t("global.review") }}</p>
  </div>
</template>