import type { AstReport } from "@/domain/models/astReport";

class AstReportParser {
  constructor() {}

  parse(rawText: string): AstReport {
    const parsedData = JSON.parse(rawText);
    const report = JSON.parse(parsedData.reportJsonStr);
    const astData = JSON.parse(parsedData.axfJsonStr);
    const complexInteractions = JSON.parse(parsedData.complexInteractionsJsonStr);

    return {
      isApproved: false,
      metadata: astData.metadata,
      alerts: report.alerts,
      complexInteractions,
      antibiotics: {
        filteredAntibiotics: report.antibiotics.filtered,
        onlyAllowedAntibiotics: report.antibiotics.onlyAllowed
      },
      mics: report.notes.micNotes,
      notes: {
        notes: report.notes.labNotes ?? report.notes.notes,
        technicianInput: report.notes.technicianInput
      },
      measurement: {
        pelletNamesImage: `data:image/jpg;base64,${astData.pelletNamesImage}`
      }
    };
  }
}

const astReportParser = new AstReportParser();

export default astReportParser;
