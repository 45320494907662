<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{ isApproved: boolean }>();

const emit = defineEmits(["approve"]);
</script>

<template>
  <v-app-bar height="74" class="px-4">
    <template v-slot:prepend>
      <v-img src="/logo.svg" width="40" height="40" alt="Antibiogo Logo" />
    </template>

    <v-app-bar-title class="font-weight-medium mx-3">{{ t("global.title") }}</v-app-bar-title>

    <template v-slot:append>
      <v-chip v-if="isApproved" color="success" variant="flat" prepend-icon="mdi-check" label>
        {{ t("global.approved") }}
      </v-chip>
      <v-btn
        v-else
        color="primary"
        variant="flat"
        class="text-none"
        data-testid="approve-btn"
        @click="emit('approve')"
      >
        {{ t("global.approve") }}
      </v-btn>
    </template>
  </v-app-bar>
</template>
