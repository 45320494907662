import { getDownloadURL, getStorage, ref, connectStorageEmulator } from "firebase/storage";

import { firebaseApp } from "@/domain/firebase/firebaseConfiguration";
import { emulatorHost, isEmulated } from "@/domain/constants/env";

class FirebaseApprovalStorage {
  private storage = getStorage(firebaseApp);

  constructor() {
    if (isEmulated) {
      connectStorageEmulator(this.storage, emulatorHost, 9199);
    }
  }

  async fetchApprovalsFileDownloadUrl(storageId: string) {
    return await getDownloadURL(ref(this.storage, `approvals/${storageId}.axf`));
  }
}

const firebaseApprovalStorage = new FirebaseApprovalStorage();

export default firebaseApprovalStorage;
