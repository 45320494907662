<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { Metadata } from "@/domain/models/ast";

const props = defineProps<{ metaData: Metadata }>();
const { t } = useI18n();
type MetaDataItem = { header: string; text: string };
const metaDataItems = computed<MetaDataItem[]>(() => {
  const metaData = props.metaData;
  const items: MetaDataItem[] = [
    { header: t("ast.id"), text: metaData.astId },
    { header: t("ast.bacteria"), text: metaData.bacteriaType },
    { header: t("ast.sample"), text: metaData.sampleType },
    { header: t("ast.technician"), text: metaData.owner },
    { header: t("ast.date"), text: new Date(props.metaData.createdAt).toLocaleDateString() }
  ];
  if (metaData.population) {
    items.push({ header: t("ast.population"), text: metaData.population });
  }
  return items;
});
</script>

<template>
  <div class="my-2">
    <p class="font-weight-medium mb-2">{{ t("ast.title") }}</p>
    <v-row>
      <v-col cols="6" v-for="item in metaDataItems" :key="item.header">
        <v-sheet>
          <p class="font-very-small color-secondary">{{ item.header }}</p>
          <p>{{ item.text }}</p>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>