import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

import "@/assets/main.scss";

const customLightTheme = {
  dark: false,
  colors: {
    primary: "#0A74D3",
    secondary: "#FFFFFF",
    accent: "#0A74D3",
    error: "#cf201d"
  }
};

export default createVuetify({
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: { mdi }
  },
  theme: {
    defaultTheme: "customLightTheme",
    themes: {
      customLightTheme
    }
  }
});
