<script setup lang="ts">
import type { AstReport } from "@/domain/models/astReport";
import AstTitle from "@/components/AstTitle.vue";
import AstIpcAlerts from "@/components/AstIpcAlerts.vue";
import AstMetaData from "@/components/AstMetaData.vue";
import AstNotes from "@/components/AstNotes.vue";
import AstMeasurement from "@/components/AstMeasurement.vue";
import AstMics from "@/components/AstMics.vue";
import AstComplexInteractions from "@/components/AstComplexInteractions.vue";
import AstAntibiotics from "@/components/AstAntibiotics.vue";

defineProps<{ report: AstReport }>();
</script>

<template>
  <div class="ma-auto pa-3 max-width">
    <ast-title />
    <ast-ipc-alerts :ipcAlerts="report.alerts" />
    <ast-meta-data :meta-data="report.metadata" />
    <ast-antibiotics :antibiotics="report.antibiotics" />
    <ast-notes :notes="report.notes" />
    <ast-measurement :measurement="report.measurement" />
    <ast-mics :mics="report.mics" />
    <ast-complex-interactions :complex-interactions="report.complexInteractions" />
  </div>
</template>