import firebaseApprovalRepository from "@/domain/firebase/firebaseApprovalRepository";
import firebaseApprovalStorage from "@/domain/firebase/firebaseApprovalStorage";
import type { AstReport } from "@/domain/models/astReport";
import astReportParser from "@/domain/parsers/astReportParser";
import type { ApprovalService } from "@/domain/services/approvals/approvalService";
import { useFetch } from "@vueuse/core";

class ApprovalFirebaseService implements ApprovalService {
  async fetchAstReport(storageId: string): Promise<AstReport> {
    const url = await firebaseApprovalStorage.fetchApprovalsFileDownloadUrl(storageId);
    const rawText = (await useFetch<string>(url)).data.value;
    if (!rawText) {
      throw new Error("File is empty or corrupted!");
    }
    const astReport = astReportParser.parse(rawText);
    astReport.isApproved = await firebaseApprovalRepository.isAstApproved(storageId);
    return astReport;
  }

  async approveAst(storageId: string) {
    return await firebaseApprovalRepository.approveAst(storageId);
  }
}

export const approvalFirebaseService = new ApprovalFirebaseService();
