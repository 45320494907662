<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div class="text-center mt-16">
    <p>{{ t("global.error_line1") }}</p>
    <p>{{ t("global.error_line2") }}</p>
  </div>
</template>