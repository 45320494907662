<template>
  <v-app>
    <v-main v-if="!isLoaded">
      <app-loader />
    </v-main>
    <template v-else>
      <app-error v-if="hasError" />
      <template v-else>
        <app-navbar :isApproved="report.isApproved" @approve="handleApproveAst" />
        <v-main>
          <app-ast-report :report="report" />
        </v-main>
      </template>
    </template>
  </v-app>
</template>
<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";

import type { AstReport } from "@/domain/models/astReport";
import { emptyAstReport } from "@/domain/models/astReport";

import { approvalService } from "@/domain/services/approvals";

import AppLoader from "@/components/AppLoader.vue";
import AppError from "@/components/AppError.vue";
import AppNavbar from "@/components/AppNavbar.vue";
import AppAstReport from "@/components/AppAstReport.vue";

const props = defineProps<{ storageId: string }>();

const isLoaded = ref<boolean>(false);
const hasError = ref<boolean>(false);
const report = reactive<AstReport>(emptyAstReport);

onMounted(async () => {
  try {
    Object.assign(report, await approvalService.fetchAstReport(props.storageId));
  } catch (e) {
    handleError(e, "Failed to fetch AST Report!");
  } finally {
    isLoaded.value = true;
  }
});

async function handleApproveAst() {
  try {
    await approvalService.approveAst(props.storageId);
    report.isApproved = true;
  } catch (e) {
    handleError(e, "Failed to approve AST!");
  }
}

function handleError(error: any, context: string) {
  hasError.value = true;
  let message = context;
  if (error.status) {
    message += `\nStatus: ${error.status}`;
  }
  if (error.message) {
    message += `\nReason: ${error.message}`;
  }
  console.error(message);
}
</script>
