import { createRouter, createWebHistory } from "vue-router";
import AstApproval from "@/views/AstApproval.vue";

export default createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/approvals",
      name: "approval",
      component: AstApproval,
      props: (route) => ({ storageId: route.query.approve })
    }
  ]
});
