import { doc, getDoc, getFirestore, setDoc, connectFirestoreEmulator } from "firebase/firestore";

import { firebaseApp } from "@/domain/firebase/firebaseConfiguration";
import { isEmulated, emulatorHost } from "@/domain/constants/env";

class FirebaseApprovalRepository {
  private db = getFirestore(firebaseApp);
  private collectionName = "approvals";

  constructor() {
    if (isEmulated && !(this.db as any)._settingsFrozen) {
      connectFirestoreEmulator(this.db, emulatorHost, 3000);
    }
  }

  async approveAst(storageId: string) {
    await setDoc(this.docRef(storageId), { approved: true }, { merge: true });
  }

  async isAstApproved(storageId: string) {
    return !!(await getDoc(this.docRef(storageId))).data()?.approved;
  }

  private docRef(storageId: string) {
    return doc(this.db, this.collectionName, storageId);
  }
}

const firebaseApprovalRepository = new FirebaseApprovalRepository();

export default firebaseApprovalRepository;
