import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { isEmulated } from "@/domain/constants/env";

export const firebaseApp = initializeApp({
  apiKey: import.meta.env.APPROVAL_FIREBASE_API_KEY,
  authDomain: import.meta.env.APPROVAL_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.APPROVAL_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.APPROVAL_FIREBASE_STORAGE_BUCKET,
  ...(!isEmulated && { messagingSenderId: import.meta.env.APPROVAL_FIREBASE_MESSAGE_SENDER_ID }),
  ...(!isEmulated && { appId: import.meta.env.APPROVAL_FIREBASE_APP_ID }),
  ...(!isEmulated && { measurementId: import.meta.env.APPROVAL_FIREBASE_MEASUREMENT_ID })
});

if (!isEmulated) {
  if (import.meta.env.APPROVAL_FIREBASE_APPCHECK_DEBUG_OVERRIDE_TOKEN != undefined) {
    globalThis.FIREBASE_APPCHECK_DEBUG_TOKEN =
      import.meta.env.APPROVAL_FIREBASE_APPCHECK_DEBUG_OVERRIDE_TOKEN;
  } else if (import.meta.env.APPROVAL_FIREBASE_APPCHECK_DEBUG_TOKEN_IN_CONSOLE_LOG === "ON") {
    globalThis.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }

  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(
      import.meta.env.APPROVAL_FIREBASE_APPCHECK_RECAPTCHA_KEY
    ),
    isTokenAutoRefreshEnabled: true
  });
}
