<script setup lang="ts">
import AppAlert from "@/components/AppAlert.vue";
import { useI18n } from "vue-i18n";

import type { Alert } from "@/domain/models/ast";

const { t } = useI18n();
defineProps<{ ipcAlerts: Alert[] }>();
</script>

<template>
  <div class="d-flex flex-column">
    <app-alert
      class="my-1"
      color="#fddedd"
      icon="mdi-alert-circle-outline"
      v-for="alert in ipcAlerts"
      :key="alert.messageId"
      :title="alert.title"
      :text="t('global.alert')"
    />
  </div>
</template>