<script setup lang="ts">
import { useI18n } from "vue-i18n";
import AppDivider from "@/components/AppDivider.vue";
import type { ComplexInteraction } from "@/domain/models/ast";

defineProps<{ complexInteractions: ComplexInteraction[] }>();
const { t } = useI18n();
</script>

<template>
  <div v-if="complexInteractions && complexInteractions.length">
    <app-divider />
    <p class="font-weight-medium">
      {{ t("misc.complex_interactions") }}
    </p>
    <div
      class="complex-interactions font-small my-3"
      v-for="ci in complexInteractions"
      :key="ci.question"
    >
      <p>
        <span class="color-secondary">{{ t("misc.question") }}. {{ ci.question }}</span>
        <br />
        <span> {{ t("misc.answer") }}. {{ ci.answer }} </span>
      </p>
    </div>
  </div>
</template>
