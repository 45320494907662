<script setup lang="ts">
import { useI18n } from "vue-i18n";
import AppDivider from "@/components/AppDivider.vue";
import type { Mic } from "@/domain/models/ast";

defineProps<{ mics: Mic[] }>();
const { t } = useI18n();
</script>

<template>
  <div v-if="mics && mics.length">
    <app-divider />
    <p class="font-weight-medium">{{ t("misc.mic") }}</p>
    <p class="my-3 font-small" v-for="mic in mics" :key="mic.antibioticName">
      <span v-if="mic.type === 'COMPLETE'">
        {{ mic.antibioticName }} • {{ mic.micValue }} mg/L
      </span>
      <span v-if="mic.type === 'ONGOING'">
        {{ mic.antibioticName }} • {{ t("misc.mic_ongoing") }}
      </span>
      <span v-if="mic.type === 'DISCARDED'">
        {{ mic.antibioticName }} • {{ t("misc.mic_unperformed") }}
      </span>
    </p>
  </div>
</template>