<script setup lang="ts">
import { useI18n } from "vue-i18n";
import AppDivider from "@/components/AppDivider.vue";
import type { Measurement } from "@/domain/models/ast";

defineProps<{ measurement: Measurement }>();

const { t } = useI18n();
</script>

<template>
  <div>
    <app-divider />
    <div class="measurement-container">
      <p class="font-weight-medium">{{ t("misc.measurement") }}</p>
      <v-img
        :src="measurement.pelletNamesImage"
        class="measurement-image mt-2 w-100 h-auto"
        alt="Pellet image"
      />
    </div>
  </div>
</template>
