<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import AppDivider from "@/components/AppDivider.vue";
import type { Notes } from "@/domain/models/ast";

const props = defineProps<{ notes: Notes }>();
const { t } = useI18n();
const notesLength = computed(
  () => props.notes.notes.length + Number(!!props.notes.technicianInput)
);
</script>
`
<template>
  <app-divider />
  <div class="notes-container">
    <p class="font-weight-medium mb-2">Notes ({{ notesLength }})</p>
    <p class="font-small font-weight-medium">{{ t("misc.notes") }}</p>
    <p class="message-content font-small my-3" v-for="note in notes.notes" :key="note.message">
      {{ note.message }}
    </p>
    <div v-if="notes.technicianInput">
      <p class="font-small font-weight-medium">{{ t("misc.technician") }}</p>
      <p class="font-small my-3" data-testid="technical-note">{{ notes.technicianInput }}</p>
    </div>
  </div>
</template>
