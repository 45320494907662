<script setup lang="ts">
import type { Antibiotic } from "@/domain/models/ast";
import AstAntibioticListItem from "@/components/AstAntibioticListItem.vue";

defineProps<{
  header: string;
  antibiotics: Array<Antibiotic>;
}>();
</script>

<template>
  <div class="mb-4">
    <p class="antibiotics-header font-small font-weight-medium mb-1">{{ header }}</p>
    <ast-antibiotic-list-item
      v-for="(antibiotic, index) in antibiotics"
      :key="index"
      :antibiotic="antibiotic"
    />
  </div>
</template>
