<template>
  <div class="text-center">
    <img class="loader-img" src="@/assets/loader.gif" alt="Antibiogo-loader" />
  </div>
</template>

<style scoped lang="scss">
img {
  width: 15%;
  margin-top: 15%;
}
</style>
